import { breakpoints } from 'src/styles/breakpoints'
import styled, { keyframes } from 'styled-components'

const animatedModal = keyframes`
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
`

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: #0000006e;
  z-index: 99999;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;  
  width: 100%;

  transition: 0.5s ease-in-out;
  animation: ${animatedModal} 0.5s ease-in-out forwards;
`

export const Content = styled.div`
  border-radius: 20px;
  background-color: #FFFFFF;
  padding: 22px 18px 32px 18px;
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 98vh;
  position: relative;
  transition: 0.5s ease-in-out;

  hr{
    margin: 16px 0;
  }

  @media (min-width: ${breakpoints.md}){
    hr{
      margin: 16px 0;
    }

    position: absolute;
    right: 0;
    height: 100vh;
    border-radius: 0;
    width: 80%;
    max-width: 590px;

    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    transition: 0.5s ease-in-out;
    animation: ${animatedModal} 0.5s ease-in-out forwards;
  }

`

export const ButtonClose = styled.div`
  display: flex;
  justify-content: end;
`

export const CardInfo = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;

  width: 235px;
  margin: auto;

  p.info-title{
    font-weight: 700;
    font-size: 14px;
    line-height: 16.94px;
    color: #161616;
    margin: 0;
  }
  p.info-desc{
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #161616;
    margin: 0;

    span{
      color: #00AA4F;
      font-weight: 700;
    }
  }
`

export const BoxValue = styled.div`
  padding: 16px 0 6px 0;
  text-align: center;

  p {
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;
    color: #161616;
    margin: 0;
    font-family: 'Sora', Helvetica, sans-serif;
  }
`

export const BoxTitle = styled.div`
  text-align: center;

  h3 {
    font-weight: 600;
    font-family: 'Inter', Helvetica, sans-serif;
    color: #161616;
    font-size: 72px;
    margin: 0;

    span {
      font-size: 36px;
    }
  }

  p {
    font-size: 14px;
    line-height: 16.94px;
    color: #161616;
    max-width: 370px;
    margin: auto;
  }
`

export const BoxIcons = styled.div`
  display: flex;
  flex-direction: column;

  gap: 24px;
  padding-top: 16px;
`

export const BoxIconMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {    
    font-weight: 700;
    font-size: 14px;
    line-height: 16.94px;
    color: #161616;
    margin: 0;
  }
  
  > div {
    display: flex;
    flex-direction: row;
  }

`

export const BoxIconMedia = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;


  p {    
    font-weight: 700;
    font-size: 14px;
    line-height: 16.94px;
    color: #161616;
    margin: 0;
  }
  
  > div {
    display: flex;
    flex-direction: row;
    gap: 4px;
  }
`

export const BoxInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: ${breakpoints.md}){
    flex-direction: row;

  }

`

export const BoxButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  a {
    height: 38px;
    text-transform: none;
  }

  @media (min-width: ${breakpoints.md}){
    display: none;
  }
`

export const BoxQrcode = styled.div`
  display: none;
  @media (min-width: ${breakpoints.md}){
    display: block;
    text-align: center;
    max-width: 100%;
    margin: auto;
    p{
      font-family: 'Inter', Helvetica, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 19.36px;
      text-align: center;
      color: #6A6C72;
    }
  }

`
