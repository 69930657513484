import { breakpoints } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const Container = styled.section`
  padding: 44px 0;
  background-color: #FFFFFF;
  width: 100%;
  overflow: hidden;
`

export const Content = styled.div`
  width: 320px;
  margin: auto;
  position: relative;

  h3 {
    font-family: 'Citrina', Helvetica, sans-serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 30.8px;
    text-align: center;

    margin-bottom: 24px;
    color: #EA7100;
  }

  p{
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: center;

    color: #161616;
  }

  @media (min-width: ${breakpoints.md}){
    width: 685px;

    h3{
      color: #161616;
    }
    p{
      color: #161616;
    }
  }

  @media (min-width: ${breakpoints.lg}){
    width: 940px;

    h3{
      font-size: 40px;
      line-height: 44px
    }
    p{
      font-size: 16px;
      line-height: 19.36px;
    }
  }

  @media (min-width: ${breakpoints.xl}){
    width: 1150px;

    h3{
      font-size: 56px;
      line-height: 61px
    }
    p{
      font-size: 24px;
      line-height: 29.05px;
    }
  }
`

export const BoxCarousel = styled.div`
  width: 544px;
  @media (min-width: ${breakpoints.md}){
    width: 816px;
  }
  @media (min-width: ${breakpoints.lg}){
    width: 1088px;
  }
  @media (min-width: ${breakpoints.xl}){
    width: 1150px;
  }
`

export const Card = styled.div`
  margin: 0 auto;
  width: 260px;

  img{
    height: 350px;
  }

  a{
    margin-top: 15px;
    text-transform: none;
  }
`
