import { breakpoints } from 'src/styles/breakpoints'
import { grayscale, white } from 'src/styles/colors'
import styled, { css, keyframes } from 'styled-components'

type ContainerProps = {
  isModal?: () => void;
}

const animatedModal = keyframes`
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
`
export const Container = styled.div<ContainerProps>`
  overflow-y: auto;
  background-color: ${white};

  h3 {
    font-family: "Sora", Helvetica, sans-serif;
  }

  ${(props: ContainerProps) => props.isModal && (
    css`
      min-height: 100vh;
      padding: 30px 24px;;
      position: fixed;
      width: 100%;
      top: 0;
      bottom: 0;
      right: 0;
      transition: 0.5s ease-in-out;
      animation: ${animatedModal} 0.5s ease-in-out forwards;

      @media (min-width: ${breakpoints.md}) {
        max-width: 596px;
        padding: 40px 70px;
      }
    `
  )}

  .form--default {   
    label {
      color: #6A6C72;
      margin-bottom: 4px;
    }

    input {
      padding: 15px;
    }

    input::placeholder {
      font-weight: 700;
    }

    .form-label-check {    
      &::before {
        top: 8px;
      }   
    }

    .form-input-check {
      &:checked ~ label {
      &::after {
        top: 12px;     
        }
      }
    }
  }
`

export const CloseButton = styled.button`
  position: absolute;
  right: 25px;
  top: 0;
  z-index: 10;
  outline: none !important;
`

export const ButtonDiv = styled.div`
  gap: 24px;

  button {
    height: 48px;
    font-weight: 700 !important;
    &.back-button {
      background-color: white;
      border: 1px solid #FFB46E;
      border-radius: 8px;
    }
  }

  button[type="submit"] {
    &:disabled {
      color: ${grayscale[300]} !important;
    }
}
`

export const FeedbackMessageDiv = styled.div`

`
