import { breakpoints } from "src/styles/breakpoints"
import { grayscale } from "src/styles/colors"
import styled from "styled-components"

export const Container = styled.section`
  padding: 40px 0;
  background-color: #fdf8ee;
  
  h3{
    color: ${grayscale[500]};
    font-family: 'Citrina', Helvetica, sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    text-align: center;
    margin-bottom: 32px;
  }

  @media (min-width: ${breakpoints.md}){
    padding: 50px 0;
  }
  @media (min-width: ${breakpoints.lg}){
    padding: 60px 0;

    h3{
      font-size: 40px;
      line-height: 48px;
    }
  }
  @media (min-width: ${breakpoints.xxl}){
    padding: 90px 0;

    h3{
      font-size: 56px;
      line-height: 61px;
    }
  }
`

export const Content = styled.div`
  width: 312px;
  margin: auto;

  @media (min-width: ${breakpoints.md}){
    width: 700px;
  }

  @media (min-width: ${breakpoints.lg}){
    width: 935px;
  }

  @media (min-width: ${breakpoints.xxl}){
    width: 1130px;
  }
`

export const BoxCards = styled.div`
  display: flex; 
  flex-direction: column;
  gap: 25px;

  @media (min-width: ${breakpoints.md}){
    flex-direction: row;
    gap: 12px;
  }

  @media (min-width: ${breakpoints.lg}){
    gap: 19px;
  }

  @media (min-width: ${breakpoints.xxl}){
    gap: 24px;
  }

`

export const Card = styled.div`
  h4{
    font-family: 'Citrina', Helvetica, sans-serif;
    font-size: 17.06px;
    font-weight: 700;
    line-height: 20.47px;
    color: #161616;
    margin-top: 24px;
  }
  
  > div{
    border-radius: 8px;
    background: none right bottom / contain no-repeat rgb(255, 122, 0);
    overflow: hidden;

    img{
      width: 100%;
    }
  }

  @media (min-width: ${breakpoints.md}){
    h4{
      font-size: 11px;
      line-height: 12px
    }
  }

  @media (min-width: ${breakpoints.md}){
    h4{
      font-size: 13px;
      line-height: 15.96px
    }
  }

  @media (min-width: ${breakpoints.xxl}){
    h4{
      font-size: 24px;
      line-height: 19.36px
    }
  }

`

export const BoxInfo = styled.div`
  padding: 36px 0;
  margin: auto;

  width: 100%;

  p{
    font-family: 'Inter', Helvetica, sans-serif;
    font-size: 14px;
    line-height: 16.94px;
    color: #161616;
  }

  @media (min-width: ${breakpoints.md}){
    p{
      font-size: 16px;
      line-height: 19.36px;
    }
  }

  @media (min-width: ${breakpoints.lg}){
    p{
      font-size: 18px;
      line-height: 21.78px;
    }
  }

`
