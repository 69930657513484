import { breakpoints } from 'src/styles/breakpoints'
import { orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Container = styled.section`
  background-color: ${white};
  padding: 35px 0;
  width: 100%;
  overflow: hidden;

  @media (min-width: ${breakpoints.md}){
    padding: 65px 0;
  }

  @media (min-width: ${breakpoints.lg}){
    padding: 40px 0;
  }

  @media (min-width: ${breakpoints.xxl}){
    padding: 90px 0;
  }
`

export const Content = styled.div`
  width: 315px;
  margin: auto;

  h3{
    font-family: 'Citrina', Helvetica, sans-serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 30.8px;
    text-align: center;
    color: ${orange[500]};    
  }

  @media (min-width: ${breakpoints.md}){
    width: 680px;
  }

  @media (min-width: ${breakpoints.lg}){
    width: 930px;

    h3{
      font-size: 40px;
      line-height: 44px;
    }
  }

  @media (min-width: ${breakpoints.xxl}){
    width: 1200px;

    h3{
      font-size: 56px;
      line-height: 61.6px;
    }
  }
`

export const BoxCarousel = styled.div`
  width: 470px;
  @media (min-width: ${breakpoints.md}){
    width: 750px;
  }
  @media (min-width: ${breakpoints.lg}){
    width: 990px;
  }
  @media (min-width: ${breakpoints.xxl}){
    width: 1350px;
  }
`

export const Card = styled.div`
  height: 290px;
  width: 215px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px;
  border: 3px solid #F5F5F5;
  background-color: #FFFFFF;
  padding: 40px 15px 20px 15px;
  margin: 0 auto;

  h4{
    font-family: 'Citrina', Helvetica, sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 19.8px;
    color: #3C3331;
    margin: 14px 0;
  }
  p{
    font-family: 'Inter', Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    color: #6A6C72;
  }

  a {
    text-transform: none;
  }
`
